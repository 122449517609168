import axios from "axios/index";
import config from "@/config/config";

const azureSSO = {
  /**
   * Asks V24 API to handle MS/Azure login. Returndata will contain an URL
   * that must user must be redirected to
   * @returns {AxiosPromise}
   */
  login() {
    return execute("login", {
      FrontEnd: "GA"
    });
  },

  /**
   * This is the next step after login() is done and user has come back from
   * the external MS login. User will return to GroupAlert (this is hard coded
   * in the V24 backend) with the code and session state that is used here.
   * @param code
   * @param sessionState
   * @returns {Promise<AxiosResponse<any>>}
   */
  getV24Session(code, sessionState) {
    const params = {
      Code: code,
      Session_State: sessionState,
      FrontEnd: "GA"
    };
    return execute("loggedin", params);
  }
};

const execute = (method, params) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: config.urlV24ApiSso + method,
      data: JSON.stringify(params)
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        if (err.response.data.Errors[0]) {
          const error = err.response.data.Errors[0];
          reject(error.Title + ": " + error.Details);
        } else if (err.response.statusText) {
          reject(err.response.statusText);
        } else {
          reject("Login failed");
        }
      });
  });
};

export default azureSSO;
