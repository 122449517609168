<template>
  <div>
    <v-container>
      <LoadingSpinner class="mt-12" v-if="!errorMsg" />
      <v-alert type="error" v-if="errorMsg">
        {{ errorMsg }}
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import azureSSO from "@/api/v24/azureSSO";

export default {
  name: "SsoMicrosoftLogin",
  components: { LoadingSpinner },

  data: () => ({
    errorMsg: ""
  }),

  created() {
    azureSSO
      .login()
      .then(res => {
        if (!res.data.RedirectUrl) {
          this.showErrorMsg("Unable to parse out redirect URL");
        } else {
          // Send user off to external SSO process
          window.location.href = res.data.RedirectUrl;
        }
      })
      .catch(errTxt => {
        this.showErrorMsg(errTxt);
      });
  },

  methods: {
    showErrorMsg(text) {
      this.errorMsg = text;
    }
  }
};
</script>
